import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MuiMenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import SelectComponent from '@shared/ui/inputs/Select';
import { PaymentSystemsUtil } from '@shared/ui/misc/CardSelect';
import Icon from '@shared/ui/icons/Icon';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import { MastercardIcon, PaypalIcon, PlusIcon, VisaIcon, } from './icons';
import SelectOption from './SelectOption';
// that`s not right approach, need to define paddings in mui theme
const Select = styled(SelectComponent)(props => ({
    '&.MuiInputBase-root.MuiOutlinedInput-root': {
        background: props.theme.customColors.menu.item.select,
        height: props.theme.baseSize * 8,
    },
}));
const MenuItem = styled(MuiMenuItem)(props => ({
    '&.Mui-selected.MuiButtonBase-root.MuiMenuItem-root': {
        borderLeft: 'none',
    },
    padding: '10px 24px 10px 10px',
    background: props.theme.customColors.shades.primaryLight,
    color: props.theme.palette.primary.main,
}));
const SelectContainer = styled.div(() => ({
    width: '100%',
}));
const AddButtonComponent = styled(ButtonCustom)(() => ({
    transition: 'none',
    border: 'none',
    '&:hover': {
        backgroundColor: 'transparent',
        border: 'none',
    },
}));
const AddPaymentContainer = styled.div(() => ({
    display: 'flex',
    padding: '0 18px 0 26px',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#F1F3F5',
    },
}));
const IconsContainer = styled.div(props => ({
    height: props.theme.baseSize * 4,
}));
const AddPaymentElement = ({ onClick = undefined }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsxs(AddPaymentContainer, { onClick: handleClick, tabIndex: -1, children: [_jsx(AddButtonComponent, { startIcon: _jsx(Icon, { IconComponent: PlusIcon, keepOriginalColor: true }), variant: 'text', children: "Add new method" }), _jsxs(IconsContainer, { children: [_jsx(VisaIcon, {}), _jsx(PaypalIcon, {}), _jsx(MastercardIcon, {})] })] }));
};
const SelectValueContainer = styled.div(props => ({
    display: 'flex',
    gap: props.theme.spacing_sizes.m,
    alignItems: 'end',
}));
const SelectValue = ({ value }) => {
    const { t } = useTranslation();
    const getPaymentSystemIcon = (type) => {
        var _a, _b;
        return (type ? (_b = (_a = PaymentSystemsUtil
            .getPaymentSystemsForSelect(t)) === null || _a === void 0 ? void 0 : _a[type]) === null || _b === void 0 ? void 0 : _b.mainIcon : () => undefined);
    };
    return (_jsxs(SelectValueContainer, { children: [_jsx(Icon, { IconComponent: getPaymentSystemIcon(value.paymentSystem), keepOriginalColor: true }), value.label] }));
};
const defineValue = (value, options) => {
    const cardOptionValue = options.find((option) => option.value === value);
    if (cardOptionValue)
        return cardOptionValue;
    return undefined;
};
const PaymentSelect = ({ label = undefined, options, value = '', paymentErrorText, onChange = undefined, onMethodAdd = undefined, onMethodEdit = undefined, renderEmptyValue = undefined, }) => {
    const [displayedValue, setDisplayedValue,] = useState(() => defineValue(value, options));
    const [isOpen, setIsOpen] = useState(false);
    const renderFn = useCallback(() => {
        if (displayedValue)
            return _jsx(SelectValue, { value: displayedValue });
        return renderEmptyValue || null;
    }, [displayedValue, renderEmptyValue]);
    const handleChange = (ev) => {
        const selectedOption = defineValue(ev.target.value, options);
        if (selectedOption) {
            setDisplayedValue(selectedOption);
        }
        if (selectedOption && onChange) {
            onChange(selectedOption);
        }
    };
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleMethodAdd = () => {
        handleClose();
        if (onMethodAdd) {
            onMethodAdd();
        }
    };
    const handleMethodEdit = () => {
        handleClose();
        if (onMethodEdit) {
            onMethodEdit();
        }
    };
    return (_jsxs(SelectContainer, { children: [label ? _jsx(InputLabel, { children: label }) : null, _jsxs(Select, { value: value, onChange: handleChange, renderValue: renderFn, variant: 'outlined', open: isOpen, onOpen: handleOpen, onClose: handleClose, children: [options.map((option) => (_jsx(MenuItem, { value: option.value, selected: option.value === (displayedValue === null || displayedValue === void 0 ? void 0 : displayedValue.value), children: _jsx(SelectOption, { option: option, onEdit: handleMethodEdit, isSelected: option.value === (displayedValue === null || displayedValue === void 0 ? void 0 : displayedValue.value), paymentErrorText: paymentErrorText }, option.value) }, option.value))), _jsx(Box, { children: _jsx(AddPaymentElement, { onClick: handleMethodAdd }) }, 'ADD')] })] }));
};
export default PaymentSelect;
